<template>
  <v-card>
    <v-card-title
      class="headline grey lighten-2"
      primary-title
    >
      <input v-model="qrcode.name" placeholder="Edit name..." @change="hasChanged">
      <v-spacer />
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn absolute right fab small v-on="on" @click.stop="$emit('close')"><v-icon>mdi-close</v-icon></v-btn>
        </template>
        <span>Close</span>
      </v-tooltip>
    </v-card-title>
    <v-card-text>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn absolute right fab small v-on="on" @click.stop="startScan"><v-icon>mdi-camera</v-icon></v-btn>
        </template>
        <span>Scan QR code</span>
      </v-tooltip>
      <v-dialog v-model="scan" :overlay-opacity="1" @keydown.esc="stopScan" @click.stop="stopScan">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn absolute right fab small v-on="on" @click.stop="stopScan"><v-icon>mdi-close</v-icon></v-btn>
          </template>
          <span>Close</span>
        </v-tooltip>
        <stream-barcode-reader ref="scanner" @decode="codeScanned"/>
      </v-dialog>
      <v-textarea v-model="qrcode.value" placeholder="Edit value..." @keydown.esc="hasChanged" @change="hasChanged"/>
      <v-combobox
        v-model="qrcode.type"
        :items="formatItems"
        :return-object="false"
        :placeholder="'QR code'"
        @change="hasChanged"/>
      <v-select
        v-model="qrcode.rotate"
        :items="rotateItems"
        :placeholder="'0°'"
        :prepend-icon="'mdi-rotate-right'"
        @change="hasChanged"/>
      <span v-if="!!errorMessage" class="error">{{errorMessage}}</span>
      <l-map ref="map" :zoom="zoom" :center="center" @update:center="updateCenter" @update:zoom="updateZoom">
        <l-tile-layer :url="url" :attribution="attribution"/>
        <l-marker :lat-lng="qrcode.latlng" :draggable="true" @update:latLng="updateMarker"/>
      </l-map>
      <canvas id="code-preview"/>
    </v-card-text>
  </v-card>
</template>

<script>
import bwipjs from 'bwip-js'
import { LMap, LMarker, LTileLayer } from 'vue2-leaflet'
import vCard from 'vcf'
import StreamBarcodeReader from './StreamBarcodeReader.vue'
import { BarcodeFormat } from '@zxing/library'

const types = [
  {
    code: 'azteccode',
    label: 'Aztec code',
    zxing: BarcodeFormat.AZTEC
  },
  {
    code: 'rationalizedCodabar',
    label: '',
    zxing: BarcodeFormat.CODABAR
  },
  {
    code: 'code39',
    label: 'Code 39',
    zxing: BarcodeFormat.CODE_39
  },
  {
    code: 'code93',
    label: '',
    zxing: BarcodeFormat.CODE_93
  },
  {
    code: 'code128',
    label: 'Code 128',
    zxing: BarcodeFormat.CODE_128
  },
  {
    code: 'datamatrix',
    label: 'Data Matrix',
    zxing: BarcodeFormat.DATA_MATRIX
  },
  {
    code: 'ean8',
    label: 'EAN-8',
    zxing: BarcodeFormat.EAN_8
  },
  {
    code: 'ean13',
    label: 'EAN-13',
    zxing: BarcodeFormat.EAN_13
  },
  {
    code: 'interleaved2of5',
    label: 'Interleaved 2 of 5 (ITF)',
    zxing: BarcodeFormat.ITF
  },
  {
    code: 'maxicode',
    label: '',
    zxing: BarcodeFormat.MAXICODE
  },
  {
    code: 'pdf417',
    label: 'PDF 417',
    zxing: BarcodeFormat.PDF_417
  },
  {
    code: 'qrcode',
    label: 'QR code',
    zxing: BarcodeFormat.QR_CODE
  },
  {
    code: 'upca',
    label: '',
    zxing: BarcodeFormat.UPC_A
  },
  {
    code: 'upce',
    label: '',
    zxing: BarcodeFormat.UPC_E
  },
  {
    code: 'code2of5',
    label: 'Code 25'
  }
]

export default {
  name: 'qrcode-tile',
  components: {
    // VueQrReader,
    LMap,
    LTileLayer,
    LMarker,
    // QrcodeStream,
    StreamBarcodeReader
  },
  props: {
    qrcode: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      scan: false,
      displayAsVCard: true,
      center: this.qrcode.latlng,
      zoom: 15,
      url: 'https://{s}.tile.osm.org/{z}/{x}/{y}.png',
      attribution: '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors',
      types,
      errorMessage: undefined,
      rotateItems: [
        { text: '0°', value: 0 },
        { text: '90°', value: 1 },
        { text: '180°', value: 2 },
        { text: '270°', value: 3 }
      ]
    }
  },
  computed: {
    formatItems () {
      return types
        .filter(format => format.code)
        .filter(format => format.label)
        .map(format => ({
          value: format.code,
          text: format.label
        }))
    }
  },
  created () {
    if (!this.qrcode.latlng) {
      this.center = this.qrcode.latlng = { lat: 45.19867, lng: 5.72434 }
      this.zoom = 5
      navigator.geolocation.getCurrentPosition((position) => {
        // eslint-disable-next-line no-undef
        this.center = this.qrcode.latlng = L.latLng(position.coords.latitude, position.coords.longitude)
        this.hasChanged()
      })
    }
  },
  mounted () {
    this.preview()
  },
  methods: {
    isVcard () {
      return this.qrcode &&
        this.qrcode.value &&
        /^BEGIN:VCARD\r?\n/.test(this.qrcode.value) &&
        /\r?\nEND:VCARD$/.test(this.qrcode.value)
    },
    createVCard () {
      this.qrcode.value = new vCard().toString() // eslint-disable-line new-cap
      this.displayAsVCard = true
      this.hasChanged()
    },
    updateCenter (latlng) {
      this.center = latlng
    },
    updateZoom (zoom) {
      this.zoom = zoom
    },
    updateMarker (latlng) {
      this.qrcode.latlng = latlng
      this.hasChanged()
    },
    init () {
      setTimeout(() => {
        this.center = this.qrcode.latlng
        this.$refs.map.mapObject.invalidateSize()
      }, 100)
    },
    hasVcardChanged (value) {
      this.qrcode.value = value
      this.hasChanged()
    },
    hasChanged () {
      this.qrcode.error = !this.preview()
      this.$emit('change')
      this.$forceUpdate()
    },
    preview () {
      if (!this.qrcode.value) {
        return false
      }
      try {
        bwipjs.toCanvas('code-preview', {
          bcid: (this.qrcode.type && this.qrcode.type.value) || this.qrcode.type || 'qrcode', // Barcode type
          text: this.qrcode.value, // Text to encode
          scale: 3, // 3x scaling factor
          includetext: true, // Show human-readable text
          textxalign: 'center' // Always good to set this
        })
        this.errorMessage = undefined
        return true
      } catch (e) {
        const canvas = document.getElementById('code-preview')
        const context = canvas.getContext('2d')
        context.clearRect(0, 0, canvas.width, canvas.height)
        this.errorMessage = e
        // eslint-disable-next-line no-console
        console.error(e)
        return false
      }
    },
    startScan () {
      this.scan = true
      if (this.$refs.scanner) {
        this.$refs.scanner.start()
      }
    },
    stopScan () {
      this.$refs.scanner.stop()
      this.scan = false
    },
    codeScanned (result) {
      this.stopScan()
      this.qrcode.value = result.text
      const format = types.find(format => format.zxing === result.format)
      this.qrcode.type = format ? format.code : 'qrcode'
      this.scan = false
      this.hasChanged()
    }
  }
}
</script>

<style scoped>
  @font-face {
    font-family: 'qrcode';
    src: url('/qr-code-webfont.woff2') format('woff2'),
        url('/qr-code-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  .v-card__title > input {
    width: 100%;
  }
  div.leaflet-container {
    margin: 0px auto;
    width: 100%;
    height: 320px;
  }
  #code-preview {
    display: block;
    margin: auto;
    margin-top: 30px;
  }
</style>
