<template>
  <v-form>
    <v-jsf v-if="schema && data" ref="vjsf" v-model="filteredData" :schema="schema" @change="saveChanges" />
  </v-form>
</template>

<script>
import VJsf from '@koumoul/vjsf'
import axios from 'axios'
import jsonpath from 'jsonpath'

import '@koumoul/vjsf/dist/main.css'

export default {
  name: 'crud',
  components: {
    VJsf
  },
  props: {
    baseUrl: {
      type: String,
      required: true
    },
    headers: {
      type: Object,
      required: false,
      default: () => ({})
    },
    collection: {
      type: String,
      required: true
    },
    uiSchema: {
      type: Object,
      required: true
    },
    filters: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  data: function () {
    return {
      schema: null,
      data: { [`${this.collection}s`]: [] },
      currentIds: []
    }
  },
  computed: {
    filteredData: {
      get: function () {
        return {
          [`${this.collection}s`]: this.data[`${this.collection}s`]
            .filter(e => Object.entries(this.filters)
              .filter(([key, value]) =>
                (value && (!e[key] || !e[key].includes(value))) ||
                (!value && !!e[key])
              )
              .length === 0
            )
        }
      },
      set: function (filteredData) {
        const unfilteredData = {
          [`${this.collection}s`]: this.data[`${this.collection}s`]
            .filter(e => Object.entries(this.filters)
              .filter(([key, value]) =>
                (value && (!e[key] || !e[key].includes(value))) ||
                (!value && !!e[key])
              )
              .length !== 0
            )
        }
        this.data = {
          [`${this.collection}s`]: unfilteredData[`${this.collection}s`].concat(filteredData[`${this.collection}s`])
        }
      }
    }
  },
  async mounted () {
    this.schema = {
      type: 'object',
      properties: {
        [`${this.collection}s`]: {
          type: 'array',
          items: (await axios.get(`${this.baseUrl}/${this.collection}?schema`, { headers: this.headers })).data
        }
      }
    }
    for (const [path, extraProperties] of Object.entries(this.uiSchema)) {
      const result = jsonpath.query(this.schema, path)
      for (const obj of result) {
        for (const [key, value] of Object.entries(extraProperties)) {
          obj[key] = value
        }
      }
    }
    this.data = {
      [`${this.collection}s`]: (await axios.get(`${this.baseUrl}/${this.collection}`, { headers: this.headers })).data
    }
    this.currentIds = this.data[`${this.collection}s`].map(data => data._id).filter(id => id)
  },
  methods: {
    saveChanges: async function () {
      const newIds = this.data[`${this.collection}s`].map(e => e._id).filter(id => id)
      const toDelete = this.currentIds.filter(oldId => newIds.indexOf(oldId) === -1)
      for (const id of toDelete) {
        axios.delete(`${this.baseUrl}/${this.collection}/${id}`, { headers: this.headers })
      }
      this.currentIds = newIds

      const changeIndexes = Object.keys(this.$refs.vjsf.inputs[0].editabledArrayProp.editedItems)
        .filter(index => this.filteredData[`${this.collection}s`][index])
      for (const index of changeIndexes) {
        const change = this.filteredData[`${this.collection}s`][index]
        if (this.filteredData[`${this.collection}s`][index]._id) {
          axios.put(`${this.baseUrl}/${this.collection}/${change._id}`, change, { headers: this.headers })
        } else {
          const obj = (await axios.post(`${this.baseUrl}/${this.collection}`, change, { headers: this.headers })).data
          this.filteredData[`${this.collection}s`][index]._id = obj._id
          setTimeout(() => this.$forceUpdate(), 1000)
        }
      }
      this.$refs.vjsf.inputs[0].editabledArrayProp.editedItems = {}
    }
  }
}
</script>
<style scoped>
.v-list {
  background: inherit;
  max-width: 100%;
  margin: 5px 10px;
}
</style>
