<template>
  <v-list-item  :class="{error: qrcode.error}" @click="editQRCode">
    <v-list-item-avatar>
      <v-icon>mdi-qrcode</v-icon>
    </v-list-item-avatar>
    <v-list-item-content @pointermove.stop>
      <v-list-item-title>{{qrcode.name}} ({{(qrcode.type && qrcode.type.value) || qrcode.type}})</v-list-item-title>
      <v-list-item-subtitle>{{qrcode.value}}</v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action @pointermove.stop>
      <span>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" @click.stop="removeQRCode"><v-icon>mdi-delete</v-icon></v-btn>
          </template>
          <span>Delete code</span>
        </v-tooltip>
      </span>
    </v-list-item-action>
    <v-dialog v-model="dialog" @keydown.esc.stop="closeDialog">
      <qrcode-edit ref="dialog" :qrcode="qrcode" @change="$emit('change')" @close="closeDialog"/>
    </v-dialog>
  </v-list-item>
</template>

<script>
import QRCodeEdit from '@/components/QRCode-edit.vue'

export default {
  name: 'qrcode-list-item',
  components: {
    'qrcode-edit': QRCodeEdit
  },
  props: {
    qrcode: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    dialog: false
  }),
  methods: {
    editQRCode () {
      this.dialog = true
      setTimeout(() => this.$refs.dialog.init(), 0)
    },
    closeDialog () {
      this.dialog = false
    },
    removeQRCode () {
      this.$emit('remove')
    }
  }
}
</script>

<style>
.v-list-item {
  margin: 2px;
  border: 1px solid gray;
  border-radius: 5px;
  cursor: pointer;
}
.v-list-item__avatar {
  cursor: move;
}
</style>
