<template>
  <div class="user">
    <div>
      <label for="password">Password :</label>
      <input id="password" v-model="password">
    </div>
    <div>
      <label for="name">Name :</label>
      <input id="name" v-model="name">
    </div>
    <div>
      <label for="email">Email :</label>
      <input id="email" v-model="email">
    </div>
    <div>
      <label for="token">Token :</label>
      <input id="token" v-model="token">
    </div>
    <v-btn @click="createUser">
      <v-icon>create</v-icon>
    </v-btn>
  </div>
</template>

<script>
import axios from 'axios'
import config from '@/config'

function random () {
  const { crypto, Uint32Array } = window
  if (typeof crypto?.getRandomValues === 'function' && typeof Uint32Array === 'function') {
    // Divide a random UInt32 by the maximum value (2^32 -1) to get a result between 0 and 1
    return window.crypto.getRandomValues(new Uint32Array(1))[0] / 4294967295
  }
  return Math.random()
}

function createToken (length = 10) {
  const chars = 'abcdefghijkmnopqrstuvwxyzABCDEFGHJKLMNPQRSTUVWXYZ123456789'
  let token = ''
  for (let i = 0; i < length; i++) {
    token += chars[Math.floor(random() * chars.length)]
  }
  return token
}

export default {
  name: 'user',
  data () {
    return {
      password: null,
      name: null,
      email: null,
      token: createToken()
    }
  },
  methods: {
    createUser () {
      axios.post(`${config.apiUrl}/users`, {
        password: this.password,
        user: {
          name: this.name,
          email: this.email,
          token: this.token
        }
      })
    }
  }
}
</script>
