import axios from 'axios'
import moment from 'moment'
import config from '@/config.js'

const state = {
  authenticated: false,
  errorMessage: 'Barcodes won\'t be saved until you don\'t have a valid token.',
  token: null,
  qrcodes: []
}

const getters = {
}

const actions = {
  async createNewToken ({ commit }) {
    let token
    try {
      const res = await axios.post(`${config.apiUrl}/users`)
      const user = res.data
      commit('updateQRCodes', user.qrcodes)
      token = res.data.token
      let message
      const expiration = moment(res.data.expiration)
      if (expiration < moment().add(2, 'w')) {
        message = `Your token will exprire ${expiration.fromNow()}.`
      }
      commit('updateErrorMessage', message)
    } catch (e) {
      console.error(e)
      commit('updateErrorMessage', 'Fail to create new token.')
    }
    commit('updateToken', { token, authenticated: !!token })
  },
  async updateToken ({ commit }, evt) {
    if (!evt) {
      commit('updateQRCodes', [])
      commit('updateToken', { token: null, authenticated: false })
      return
    }
    const token = evt.target
      ? evt.target.value
      : evt
    let authenticated = false
    try {
      const res = await axios.get(`${config.apiUrl}/users/${token}`)
      const user = res.data
      commit('updateQRCodes', user.qrcodes)
      authenticated = true
      let message
      if (res.data.expiration) {
        const expiration = moment(res.data.expiration)
        if (expiration < moment().add(2, 'w')) {
          message = `Your token will exprire ${expiration.fromNow()}.`
        }
      }
      commit('updateErrorMessage', message)
    } catch (e) {
      commit('updateQRCodes', [])
      if (e.response) {
        switch (e.response.status) {
          case 403:
            commit('updateErrorMessage', 'Wrong token.')
            break
          case 429:
            commit('updateErrorMessage', 'You have been temporary banned, please come back later.')
            break
          case 498:
            commit('updateErrorMessage', 'Your token has expired. Please contact the developer.')
            break
          default:
            commit('updateErrorMessage', 'Barcodes won\'t be saved until you don\'t have a valid token.')
            break
        }
      }
    }
    commit('updateToken', { token, authenticated })
  },
  async addQRCode ({ commit, dispatch }) {
    const qrcode = { name: null, value: null }
    commit('addQRCode', qrcode)
    await dispatch('saveQRCodes')
  },
  async deleteQRCode ({ commit, dispatch }, index) {
    commit('deleteQRCode', index)
    await dispatch('saveQRCodes')
  },
  async saveQRCodes ({ commit, state }) {
    if (!state.authenticated) {
      return
    }
    if (state.qrcodes.some(qrcode => qrcode.error)) {
      commit('updateErrorMessage', 'Barcodes won\'t be saved until they contain an error.')
      return
    }
    commit('updateErrorMessage', undefined)
    await axios.put(
      `${config.apiUrl}/users/${state.token}/qrcodes`,
      state.qrcodes
        .map(qrcode => ({ ...qrcode, type: (qrcode.type && qrcode.type.value) || qrcode.type || 'qrcode' }))
    )
  },
  async updateQRCodes ({ commit, dispatch }, qrcodes) {
    commit('updateQRCodes', qrcodes)
    await dispatch('saveQRCodes')
  }

}

const mutations = {
  updateErrorMessage (state, errorMessage) {
    state.errorMessage = errorMessage
  },
  updateToken (state, { token, authenticated }) {
    state.token = token
    state.authenticated = authenticated
  },
  updateQRCodes (state, qrcodes) {
    state.qrcodes = qrcodes
  },
  addQRCode (state, qrcode) {
    state.qrcodes.push(qrcode)
  },
  deleteQRCode (state, index) {
    state.qrcodes.splice(index, 1)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
