<template>
  <div class="qrcode">
    <p>
      <label for="token">Token : </label>
      <input id="token" :value="token" :class="{valid: authenticated, invalid: !authenticated}" :type="isNew||showToken?'text':'password'" placeholder="Edit your token" @change="onTokenChange">
      <v-btn v-if="!isNew" icon @click="showToken = !showToken"><v-icon v-if="showToken">mdi-eye-off</v-icon><v-icon v-if="!showToken">mdi-eye</v-icon></v-btn>
      <v-tooltip v-if="!authenticated" bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" @click="confirmNewToken = true"><v-icon>mdi-plus</v-icon></v-btn>
        </template>
        <span>Create new token</span>
      </v-tooltip>
      <span v-if="!!errorMessage" class="error">{{errorMessage}}</span>
    </p>
    <p>
      <input id="rememberme" v-model="rememberme" type="checkbox" @change="onRemembermeChange">
      <label for="rememberme">remember me</label>
    </p>
    <p v-if="authenticated && qrcodes">
      <v-list dense>
        <draggable v-model="qrcodes" group="qrcode">
          <qrcode-list-item
            v-for="(qrcode, index) in qrcodes"
            ref="qrcode"
            :key="index"
            :qrcode="qrcode"
            @change="saveQRCodes"
            @remove="deleteQRCode(index)"
          />
        </draggable>
      </v-list>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" @click="createQRCode"><v-icon>mdi-plus</v-icon></v-btn>
        </template>
        <span>Add QR code</span>
      </v-tooltip>
    </p>
    <v-dialog v-model="confirmNewToken" max-width="290">
      <v-card>
        <v-card-title class="headline">Create new token?</v-card-title>
        <v-card-text>You will create a new temporay token, do you want to continue ?</v-card-text>

        <v-card-actions @click="confirmNewToken = false">
          <v-spacer />
          <v-btn color="red darken-1" text><v-icon>mdi-close</v-icon></v-btn>
          <v-btn color="green darken-1" text @click="createToken"><v-icon>mdi-check</v-icon></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import draggable from 'vuedraggable'
import QRCodeListItem from '@/components/QRCode-list-item.vue'

const { mapState, mapActions } = createNamespacedHelpers('app')

export default {
  name: 'qrcode-list',
  components: {
    draggable,
    'qrcode-list-item': QRCodeListItem
  },
  data () {
    return {
      rememberme: !!localStorage.token,
      showToken: false,
      isNew: false,
      confirmNewToken: false
    }
  },
  computed: {
    ...mapState({
      authenticated: 'authenticated',
      errorMessage: 'errorMessage',
      token: 'token',
      _qrcodes: 'qrcodes'
    }),
    qrcodes: {
      get: function () { return (this._qrcodes) },
      set: function (qrcodes) { this.updateQRCodes(qrcodes) }
    }
  },
  created () {
    this.updateQRCodes([])
    const token = this.$route.query.token || localStorage.token
    this.updateToken(token)
  },
  methods: {
    ...mapActions([
      'createNewToken',
      'updateToken',
      'addQRCode',
      'deleteQRCode',
      'saveQRCodes',
      'updateQRCodes'
    ]),
    createToken () {
      this.createNewToken()
      this.isNew = true
    },
    createQRCode () {
      this.addQRCode()
      setTimeout(() => {
        this.$refs.qrcode.slice(-1)[0].editQRCode()
      }, 100)
    },
    onTokenChange (evt) {
      const token = evt.target.value
      if (this.rememberme) {
        localStorage.token = token
      }
      this.isNew = false
      this.updateToken(token)
    },
    onRemembermeChange () {
      if (this.rememberme) {
        localStorage.token = this.token ? this.token : ''
      } else {
        localStorage.clear()
      }
    }
  }
}
</script>
<style scoped>
.invalid {
  border: 1px solid red;
  background-color: hsl(0, 50%, 90%)
}
input {
  border: 1px solid black;
}
p:first-child {
  margin-bottom: 0;
}
p:nth-child(2) {
  transform-origin: top left;
  transform: scale(0.75);
}
</style>
