<template>
  <v-app>
    <v-app-bar app>
      <v-icon>mdi-qrcode</v-icon>
      <v-toolbar-title>Barcode Wallet Manager</v-toolbar-title>
      <v-spacer />
    </v-app-bar>
    <v-content>
      <router-view/>
    </v-content>
  </v-app>
</template>

<script>

export default {
  name: 'BarcodeWalletManager'
}
</script>

<style>
.error {
  padding: 5px;
  border-radius: 5px;
}
</style>
