<template>
  <div>
    <p>
      <label for="token">Token : </label>
      <input id="token" :value="token" :class="{valid: authenticated, invalid: !authenticated}" type="password" placeholder="Edit your token" @change="onTokenChange">
      <span v-if="!!errorMessage" class="error">{{errorMessage}}</span>
    </p>
    <p>
      <input id="rememberme" v-model="rememberme" type="checkbox" @change="onRemembermeChange">
      <label for="rememberme">remember me</label>
    </p>
    <crud-component v-if="authenticated" :base-url="baseUrl" collection="users" :ui-schema="uiSchema" :headers="{'x-token': token}"/>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import config from '@/config'
import CrudComponent from '../components/CrudComponent.vue'

const { mapState, mapActions } = createNamespacedHelpers('app')

export default {
  components: { CrudComponent },
  data: function () {
    return {
      baseUrl: config.apiUrl,
      rememberme: !!localStorage.token,
      uiSchema: {
        '$..properties.qrcodes': { 'x-style': { display: 'none' } },
        '$..properties._id': { 'x-style': { display: 'none' } },
        '$..properties.__v': { 'x-style': { display: 'none' } },
        '$..properties.name': { 'x-cols': 4 },
        '$..properties.email': { 'x-cols': 4 },
        '$..properties.token': { 'x-cols': 4 },
        '$..properties.lastSeen': { 'x-cols': 4 },
        '$..properties.creation': { 'x-cols': 4 },
        '$..properties.expiration': { 'x-cols': 4 }
      }
    }
  },
  computed: {
    ...mapState({
      authenticated: 'authenticated',
      errorMessage: 'errorMessage',
      token: 'token'
    })
  },
  created () {
    const token = this.$route.query.token || localStorage.token
    this.updateToken(token)
  },
  methods: {
    ...mapActions([
      'updateToken'
    ]),
    onTokenChange (evt) {
      const token = evt.target.value
      if (this.rememberme) {
        localStorage.token = token
      }
      this.updateToken(token)
    },
    onRemembermeChange () {
      console.log(this.rememberme, this.token)
      if (this.rememberme) {
        localStorage.token = this.token ? this.token : ''
      } else {
        localStorage.clear()
      }
    }
  }
}
</script>
