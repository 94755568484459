import Vue from 'vue'
import Vuetify, {
  VBtn,
  VCard,
  VCardActions,
  VCardText,
  VCardTitle,
  VChip,
  VCol,
  VCombobox,
  VDatePicker,
  VDialog,
  VIcon,
  VInput,
  VListItemContent,
  VListItemTitle,
  VMenu,
  VRow,
  VSelect,
  VSpacer,
  VTab,
  VTabs,
  VTabItem,
  VTextField,
  VTimePicker
} from 'vuetify/lib'
import VJsf from '@koumoul/vjsf'
import draggable from 'vuedraggable'

// import '@koumoul/vjsf/dist/third-party.js'
import '@mdi/font/css/materialdesignicons.css'
import '@koumoul/vjsf/dist/main.css'

Vue.use(Vuetify, {
  components: {
    draggable,
    VBtn,
    VCard,
    VCardActions,
    VCardText,
    VCardTitle,
    VChip,
    VCol,
    VCombobox,
    VDatePicker,
    VDialog,
    VIcon,
    VInput,
    VListItemContent,
    VListItemTitle,
    VMenu,
    VRow,
    VSelect,
    VSpacer,
    VTab,
    VTabItem,
    VTabs,
    VTextField,
    VTimePicker,
    VJsf
  }
})

const opts = {}

export default new Vuetify(opts)
